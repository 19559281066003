import React from 'react';
import './Home.css'; // Assuming you have a CSS file for styling

export const Home = () => {
  return (
    <div className="home-container">
      <main className="home-main">
        <h1>Welcome to Talha Khan's Homepage</h1>
        <h2>Engineer, Scientist, Inventor, Philosopher, Politician, and Theologist</h2>
        <section className="biography">
          <h3>Biography</h3>
          <p>
            Talha Khan is an accomplished engineer, scientist, inventor, philosopher, politician, and theologist. 
            With a diverse background and a passion for knowledge, Talha has made significant contributions in various fields. 
            As an engineer, he has worked on groundbreaking projects that have advanced technology and innovation. 
            In the realm of science, his research has led to numerous discoveries that have had a profound impact on our understanding of the world. 
            Talha's inventive spirit has resulted in several patents and revolutionary products that have changed the way we live.
          </p>
          <p>
            As a philosopher, Talha delves into the fundamental questions of existence, seeking to understand the nature of reality, 
            consciousness, and the human experience. His writings and lectures have inspired many to think deeply about the world around them. 
            In the political arena, Talha has been a vocal advocate for progressive policies that promote social justice, equality, and environmental sustainability.
          </p>
          <p>
            Talha's theological studies have provided him with a unique perspective on the intersection of science and religion. 
            He explores the spiritual dimensions of life and seeks to find common ground between different belief systems. 
            Through his work, Talha aims to bridge the gap between science and spirituality, encouraging a holistic understanding of the universe.
          </p>
          <p>
            Talha Khan continues to push the boundaries of knowledge and innovation, driven by a relentless curiosity and a commitment to making the world a better place. 
            His journey is a testament to the power of interdisciplinary thinking and the importance of pursuing one's passions.
          </p>
        </section>
      </main>
    </div>
  );
};
